import asteroidsImg from './img/thumbnails/asteroids.png';
import furenuKoto from './img/thumbnails/FurenuKoto.png';

import './work.css';

const Work = (props) => {
    return (
        <div className="work">
            <a href={props.link}>
                <img src={props.img} alt="作ったものへのリンク"/>
            </a>
            <p>{props.title}</p>
        </div>
    );
}

export const Asteroids = () => {
    return (
        <Work title="Asteroids" img={asteroidsImg} link="https://asteroids-c7i.pages.dev/" />
    );
}

export const FurenuKoto = () => {
    return (
        <Work title="Furenu Koto" img={furenuKoto} link="https://github.com/RitsuMurakami/FurenuKoto" />
    );
}
