// images
import portrait from './img/Ritsu.png';
import Xlogo from './img/twitter-logo.png';
import GitHublogo from './img/github-mark-white.png';

// works
import { Asteroids, FurenuKoto } from './work.js';

// css
import './App.css';

function Portrait() {
  return (
    <div className='portrait'>
      <img src={portrait} alt="横向きの顔が描かれたイラストレーション"/>
    </div>
  );
}

function Links() {
  return (
    <div className='links'>
      <lu>
        <li><a href="#about">About</a></li>
        <li><a href="#works">Works</a></li>
        <li><a href="#contact">Contact</a></li>
      </lu>
    </div>
  );
}

function About() {
  return (
    <div className='about'>
      <h1 id="about">About</h1>

      <h3>基本</h3>
      <p>2005年生まれ</p>
      <p>東京多摩在住</p>
      <p>東京都立産業技術高等専門学校在学</p>

      <h3>好きなこと</h3>
      <p>読書 散歩 ゲーム制作 お絵描き レトロゲームで遊ぶ 美術館へ行く</p>

      <h3>好きな音楽</h3>
      <p>モダンジャズ YMO シティポップ</p>

      <h3>勉強したいこと</h3>
      <p>CG ゲーム開発 線形代数 統計 ニューラルネットワーク コンピュータアーキテクチャ オートマトン 物理 英語</p>
    </div>
  );
}

function Works() {
  return (
    <div className="works">
      <h1 id="works">Works</h1>
        <div className='contents'>
          <Asteroids />
          <FurenuKoto />
        </div>
    </div>
  );
}

function Contact() {
  return (
    <div className='contact'>
      <h1 id="contact">Contact</h1>
        <a href="https://twitter.com/rmurakami256">
          <div className='twitter'>
              <img src={Xlogo} alt="X(Twitter)のロゴ"/>
          </div>
        </a>
      <a href="https://github.com/RitsuMurakami">
        <div className='github'>
          <img src={GitHublogo} alt="GitHubのロゴ"/>
        </div>
      </a>
      <div className='mail'>
        rmurakami256<span className='atmark'></span>gmail.com
      </div>
    </div>
  );
}

function Header() {
  return (
    <header>
      <Links />
    </header>
  );
}

function Footer() {
  return (
    <footer>

    </footer>
  );
}

function Body() {
  return (
    <body>
      <Portrait />
      <About />
      <Works />
      <Contact />
    </body>
  );
}

function App() {
  return (
    <div className="App">
      <Header />
      <Body />
      <Footer />
    </div>
  );
}

export default App;
